import React, {useState} from "react";
import * as styles from "./our-services.module.scss";
import Layout from "../../components/layout/layout";
import {graphql} from "gatsby";
import {OurServicesQuery} from "../../../graphql-types";
import PageCover from "../../components/common/page-cover/page-cover";
import LinksGroupSide from "../../components/common/links-group-side/links-group-side";
import RichDatoCmsContent from "../../components/rich-dato-cms-content/rich-dato-cms-content";
import useWindowWidth from "../../hooks/use-window-width";

type ReactProps = {
    data: OurServicesQuery
}

const OurServicesPage:React.FC<ReactProps> = ({data}) => {

    const isMobile = useWindowWidth() <= 1200;
    const [selectors, setSelectors] = useState(
        [{id: 1, title: data.datoCmsProjet.mainPageButtonTitle, content: data.datoCmsProjet.nosPrestationsContent, selected: true, link: "/vos-projets"},
            {id: 2, title: data.datoCmsProjet.contentYouthButtonTitle, content: data.datoCmsProjet.contentYouth, selected: false, link: "/vos-projets/sorties-scolaires"},
            {id: 3, title: data.datoCmsProjet.contentAdultsButtonTitle, content: data.datoCmsProjet.contentAdults, selected: false, link: "/vos-projets/colonies-de-vacances"},
            {id: 4, title: data.datoCmsProjet.contentSportsButtonTitle, content: data.datoCmsProjet.contentSports, selected: false, link: "/vos-projets/sorties-groupe-adultes"},
            {id: 5, title: data.datoCmsProjet.contentTourismAndEventsButtonTitle, content: data.datoCmsProjet.contentTourismAndEvents, selected: false, link: "/vos-projets/associations-clubs-sportifs"},
            {id: 6, title: data.datoCmsProjet.contentTourismProfessionalsButtonTitle, content: data.datoCmsProjet.contentTourismProfessionals, selected: false, link: "/vos-projets/professionnels-de-tourisme"}]
    );
    const [sContent, setScontent] = useState(selectors[0].content);
    console.log(sContent)
    return (
        <Layout data={data.datoCmsFooter} stickyButtonsData={data.datoCmsWebStickyButton} chatbotData={data.datoCmsChatbot} seo={data.datoCmsProjet.seo}         pageTemplate="our services">
            <PageCover
                title={data.datoCmsProjet.cover[0] && data.datoCmsProjet.cover[0].title}
                image={data.datoCmsProjet.cover[0] && data.datoCmsProjet.cover[0].backgroundImage}
            />
            <section className={styles.pageContainer}>
                <div className={styles.pageContent}>
                    {!isMobile && <LinksGroupSide data={data.datoCmsProjet.linksGroup} />}
                    <div className={styles.content}>
                        <div className={styles.buttonsContainer}>
                            {selectors.map((selector, index) => {
                                return (
                                    <a
                                        aria-label={selector.title}
                                        key={selector.id}
                                        className={selector.selected ? styles.buttonSelected : styles.button}
                                        href={selector.link}
                                    >
                                        {selector.title}
                                    </a>
                                )
                            })}
                        </div>
                        {sContent && <RichDatoCmsContent data={sContent} />}
                    </div>
                    {isMobile && <LinksGroupSide data={data.datoCmsProjet.linksGroup} />}
                </div>
            </section>
        </Layout>
    )

}

export const fragment = graphql`
    fragment ProjetFields on DatoCmsProjet {
        seo {
          description
          title
        }
        cover {
            title
            backgroundImage {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
        }
        linksGroup {
            id
            title
            titleAttribute
            slug
            mainColor {
                hex
            }       
            icon {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
            iconHover {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
            backgroundIcon {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
            backgroundIconHover {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
        }
        nosPrestationsContent {
            __typename
            ... on DatoCmsTitle {
                ...TitleFields
            }
            ... on DatoCmsParagraph {
                ...ParagraphFields
            }
            ... on DatoCmsSubtitle {
                ...SubtitleFields
            }
            ... on DatoCmsPicturedParagraph {
                ...PicturedParagraphFields
            }
            ... on DatoCmsDetailedOutlinedBloc {
                ...DetailedOutlinedBlockFields
            }
            ... on DatoCmsOutlinedBloc {
                ...OutlinedBlockFields
            }
            ...on DatoCmsTitleParagraphBlueBloc {
                ...TitleParagraphBlueBlockFields
            }
        }
        mainPageButtonTitle
        contentYouthSeo {
          description
          title
        }
        contentYouthPageCover {
            title
            backgroundImage {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
        }
        contentYouthButtonTitle
        contentYouth {
            __typename
            ... on DatoCmsTitle {
                ...TitleFields
            }
            ... on DatoCmsParagraph {
                ...ParagraphFields
            }
            ... on DatoCmsSubtitle {
                ...SubtitleFields
            }
            ... on DatoCmsPicturedParagraph {
                ...PicturedParagraphFields
            }
            ... on DatoCmsDetailedOutlinedBloc {
                ...DetailedOutlinedBlockFields
            }
            ... on DatoCmsOutlinedBloc {
                ...OutlinedBlockFields
            }
            ...on DatoCmsTitleParagraphBlueBloc {
                ...TitleParagraphBlueBlockFields
            }
        }
        contentAdultsSeo {
          description
          title
        }
        contentAdultsPageCover {
            title
            backgroundImage {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
        }
        contentAdultsButtonTitle
        contentAdults {
            __typename
            ... on DatoCmsTitle {
                ...TitleFields
            }
            ... on DatoCmsParagraph {
                ...ParagraphFields
            }
            ... on DatoCmsSubtitle {
                ...SubtitleFields
            }
            ... on DatoCmsPicturedParagraph {
                ...PicturedParagraphFields
            }
            ... on DatoCmsDetailedOutlinedBloc {
                ...DetailedOutlinedBlockFields
            }
            ... on DatoCmsOutlinedBloc {
                ...OutlinedBlockFields
            }
            ...on DatoCmsTitleParagraphBlueBloc {
                ...TitleParagraphBlueBlockFields
            }
        }
        contentSportsSeo {
          description
          title
        }
        contentSportsPageCover {
            title
            backgroundImage {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
        }
        contentSportsButtonTitle
        contentSports {
            __typename
            ... on DatoCmsTitle {
                ...TitleFields
            }
            ... on DatoCmsParagraph {
                ...ParagraphFields
            }
            ... on DatoCmsSubtitle {
                ...SubtitleFields
            }
            ... on DatoCmsPicturedParagraph {
                ...PicturedParagraphFields
            }
            ... on DatoCmsDetailedOutlinedBloc {
                ...DetailedOutlinedBlockFields
            }
            ... on DatoCmsOutlinedBloc {
                ...OutlinedBlockFields
            }
            ...on DatoCmsTitleParagraphBlueBloc {
                ...TitleParagraphBlueBlockFields
            }
        }
        contentTourismAndEventsSeo {
          description
          title
        }
        contentTourismAndEventsPageCover {
            title
            backgroundImage {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
        }
        contentTourismAndEventsButtonTitle
        contentTourismAndEvents {
            __typename
            ... on DatoCmsTitle {
                ...TitleFields
            }
            ... on DatoCmsParagraph {
                ...ParagraphFields
            }
            ... on DatoCmsSubtitle {
                ...SubtitleFields
            }
            ... on DatoCmsPicturedParagraph {
                ...PicturedParagraphFields
            }
            ... on DatoCmsDetailedOutlinedBloc {
                ...DetailedOutlinedBlockFields
            }
            ... on DatoCmsOutlinedBloc {
                ...OutlinedBlockFields
            }
            ...on DatoCmsTitleParagraphBlueBloc {
                ...TitleParagraphBlueBlockFields
            }
        }
        contentTourismProfessionalsSeo {
          description
          title
        }
        contentTourismProfessionalsPageCover {
            title
            backgroundImage {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
        }
        contentTourismProfessionalsButtonTitle
        contentTourismProfessionals {
            __typename
            ... on DatoCmsTitle {
                ...TitleFields
            }
            ... on DatoCmsParagraph {
                ...ParagraphFields
            }
            ... on DatoCmsSubtitle {
                ...SubtitleFields
            }
            ... on DatoCmsPicturedParagraph {
                ...PicturedParagraphFields
            }
            ... on DatoCmsDetailedOutlinedBloc {
                ...DetailedOutlinedBlockFields
            }
            ... on DatoCmsOutlinedBloc {
                ...OutlinedBlockFields
            }
            ...on DatoCmsTitleParagraphBlueBloc {
                ...TitleParagraphBlueBlockFields
            }
        }
    }
`

export const pageQuery = graphql`
    query OurServices($locale: String!) {  
        datoCmsFooter(locale: {  eq: $locale }) {
            ...FooterFields
        }
        datoCmsProjet(locale: { eq: $locale }) {
            ...ProjetFields
        }
        datoCmsWebStickyButton(locale: {  eq: $locale }) {
            ...StickyButtonsFields
        }
        datoCmsChatbot(locale: { eq: $locale }) {
            ...ChatbotFields
        }
    }
`

export default OurServicesPage;